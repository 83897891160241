import {
	isRouteErrorResponse,
	json,
	Link,
	useRouteError
} from "react-router-dom";
import "./FtuePage.css";
import ApiInterface from "../services/api-client/api-interface";
import {
	API_HTTP,
	resumeAnalyzerApiEndpoints
} from "../services/api-client/endpoints";
import { FC, useContext, useEffect } from "react";
import emailjs from "@emailjs/browser";
import { AuthContext, AuthContextType } from "../context/AuthProvider";

const ftueLoader = async ({ request }: { request: Request }): Promise<null> => {
	const params = new URL(request.url).searchParams;
	const checkoutSessionId = params.get("session_id");
	const campaignId = params.get("utm_campaign");
	try {
		if (checkoutSessionId) {
			const apiInterface = new ApiInterface(API_HTTP.resumeAnalyzer);
			await apiInterface.post(
				resumeAnalyzerApiEndpoints.orders,
				{
					checkoutSessionId,
					campaignId: campaignId ? parseInt(campaignId) : undefined
				},
				{},
				{
					// 30 second timeout
					timeout: 30 * 1000
				}
			);
		}
	} catch (err: any) {
		if (err?.name === "AxiosError") {
			// Network errors fail silently
			if (err?.code === "ERR_NETWORK") {
				return null;
			}
			// Handle failure in error boundary
			if (err?.code === "ERR_BAD_RESPONSE") {
				throw json({ ...err, checkoutSessionId }, { status: 500 });
			}
		}
		throw err;
	}
	return null;
};

const FtueErrorBoundary: FC = () => {
	// Assume this is being used within an AuthProvider
	const { user } = useContext(AuthContext) as AuthContextType;

	const error = useRouteError();

	if (
		!(
			isRouteErrorResponse(error) &&
			error?.data?.name === "AxiosError" &&
			error?.data?.code === "ERR_BAD_RESPONSE"
		)
	) {
		throw error;
	}

	useEffect(() => {
		// Send a support email
		const emailTemplateParams = {
			user_name: user?.displayName ?? "(unauthenticated user)",
			user_email: user?.email ?? "(none)",
			message: `THIS IS AN AUTOMATED MESSAGE.\nThe orders endpoint failed with status code ${error.data.status ?? "(none)"} and the following response data: "${error.data.response?.data ?? "(none)"}"\nCheckout Session ID: ${error.data.checkoutSessionId ?? "(none)"}`
		};
		emailjs.send("service_r2b56bq", "template_t4ppi13", emailTemplateParams, {
			publicKey: "14MIszdD7MNeQovxa"
		});
	});

	return (
		<div className="ftue-swimlanes-container">
			<p>
				Looks like that did not work the way we expected it to.
				<br />
				We have filed a ticket and someone will reach out to you soon.
			</p>
			<Link to="/newlanding" className="underline">
				Click here to return to the landing page
			</Link>
		</div>
	);
};

const FtuePage = () => {
	return (
		<div className="ftue-swimlanes-container">
			<h1 className="ftue-swimlanes-thankyou">Thank you for your purchase!</h1>
			<p className="ftue-swimlanes-text">Here's how it works...</p>
			<video
				className="ftue-swimlanes-video"
				title="Click to watch the instructional video"
				controls
			>
				<source
					src="https://storage.googleapis.com/website_jobmap_work/instructions.mp4"
					type="video/mp4"
				/>
				Your browser does not support the video tag.
			</video>
			<br />
			<Link className="ftue-swimlanes-begin-button" to="/dnd">
				Start Your Search
			</Link>
		</div>
	);
};

export { ftueLoader, FtueErrorBoundary };
export default FtuePage;
