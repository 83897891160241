import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { API_HTTP } from "./endpoints";
import { User } from "firebase/auth";

interface PathParameters {
	[key: string]: any;
}

export default class ApiInterface {
	private callerInstance: AxiosInstance;
	private user?: User;

	constructor(endpoint: API_HTTP, user?: User) {
		this.callerInstance = axios.create({
			baseURL: endpoint,
			timeout: 10 * 1000
		});
		this.user = user;
	}

	private substitutePathParams(path: string, pathParams: PathParameters) {
		const formattedPath = Object.keys(pathParams).reduce(
			(formattedPath, paramKey) =>
				formattedPath.replace(`{${paramKey}}`, pathParams[paramKey].toString()),
			path
		);
		const unsubstitutedParams = [...formattedPath.matchAll(/(?<={)\w+(?=})/g)];
		if (unsubstitutedParams.length) {
			throw new Error(
				`The following path parameters were not provided: ${unsubstitutedParams.join(", ")}`
			);
		}
		return formattedPath;
	}

	private async authorizeAxiosConfig(config?: AxiosRequestConfig) {
		if (this.user) {
			const idToken = await this.user.getIdToken();
			return {
				...config,
				headers: {
					Authorization: `Bearer ${idToken}`,
					// Provided config may override automatic authorization
					...config?.headers
				}
			};
		} else {
			return config;
		}
	}

	public async get(
		httpPathExtension: string,
		pathParams: PathParameters = {},
		config?: AxiosRequestConfig
	) {
		// Substitute URL path parameters
		const formattedPath = this.substitutePathParams(
			httpPathExtension,
			pathParams
		);
		// Authorize request with token
		config = await this.authorizeAxiosConfig(config);
		// Optional URL query parameters must be passed via the Axios request config as "params"
		return this.callerInstance
			.get(formattedPath, config)
			.then((res) => res.data);
	}

	public async post(
		httpPathExtension: string,
		data?: any,
		pathParams: PathParameters = {},
		config?: AxiosRequestConfig
	) {
		const formattedPath = this.substitutePathParams(
			httpPathExtension,
			pathParams
		);
		// Authorize request with token
		config = await this.authorizeAxiosConfig(config);
		return this.callerInstance
			.post(formattedPath, data, config)
			.then((res) => res.data);
	}

	public async patch(
		httpPathExtension: string,
		data?: any,
		pathParams: PathParameters = {},
		config?: AxiosRequestConfig
	): Promise<any> {
		// Substitute URL path parameters
		const formattedPath = this.substitutePathParams(
			httpPathExtension,
			pathParams
		);
		// Authorize request with token
		config = await this.authorizeAxiosConfig(config);
		// Optional URL query parameters must be passed via the Axios request config as "params"
		return this.callerInstance
			.patch(formattedPath, data, config)
			.then((res) => res.data);
	}
}
