import React from "react";
import "./LabeledText.css";

interface labelsProps {
	children: string;
	label: string;
}

const LabeledText = ({ children, label }: labelsProps) => {
	return (
		<div className="labeled-container">
			<span className="labeled-tag">{label}</span>
			<div className="labeled-text">{children}</div>
		</div>
	);
};

export default LabeledText;
