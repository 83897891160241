// mdx editor imports
import "@mdxeditor/editor/style.css";
import {
	MDXEditor,
	BoldItalicUnderlineToggles,
	toolbarPlugin,
	UndoRedo,
	MDXEditorMethods,
	headingsPlugin,
	listsPlugin,
	linkPlugin,
	CreateLink,
	ListsToggle,
	BlockTypeSelect,
	quotePlugin,
	thematicBreakPlugin,
	InsertThematicBreak,
	linkDialogPlugin
} from "@mdxeditor/editor";

// Library imports
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// Local imports
import Modal from "./Modal";
import "./Profile.css";
import { getUserProfile } from "./localStorage";
import { ResumeFileInput } from "./components/ResumeFileInput";
import { ToastContainer } from "react-toastify";
import { useQuery } from "@tanstack/react-query";
import ApiInterface from "./services/api-client/api-interface";
import {
	API_HTTP,
	resumeAnalyzerApiEndpoints
} from "./services/api-client/endpoints";
import { AuthContext, AuthContextType } from "./context/AuthProvider";
import { User } from "firebase/auth";

interface ProfileData {
	name: string;
	email: string;
	phone: string;
	title?: string;
	address?: string;
	city?: string;
	state?: string;
	zipCode?: string;
	linkedinUrl?: URL;
	// TODO: Change type of ProfileData.resumes from any[] to a more specific type
	resumes: any[];
	ksa: {
		knowledge: string[];
		skills: string[];
		abilities: string[];
	};
}

function ProfileBadge() {
	const { user } = useContext(AuthContext) as AuthContextType;

	// Initial state is retrieved with this initializer function to avoid doing it every time the component renders
	const getInitialProfileState = () => {
		// Retrieve profile from local storage
		// TODO: In the future this would be an API query to retrieve the authenticated user's profile
		const profile = getUserProfile()?.profile;

		// Some browsers may disable local storage
		// This would be the equivalent of receiving no valid API response
		if (!profile) {
			setTimeout(() => alert("Unable to access local storage."), 100);
		}

		return profile;
	};

	// TODO: Add setProfileData function when the profile needs to be changed/updated
	const [profileData] = useState<ProfileData | undefined>(
		getInitialProfileState
	);

	const [isProfileModalOpen, setProfileModalOpen] = useState<boolean>(false);

	const handleOpenAddJobModal = () => {
		// Do not open profile modal if profileData is not set
		profileData && setProfileModalOpen(true);
	};

	const handleCloseProfileModal = () => {
		setProfileModalOpen(false);
	};

	return (
		<>
			<div className="profile-badge" onClick={handleOpenAddJobModal}>
				{user?.photoURL && (
					<img
						className="profile-badge-picture"
						src={user.photoURL}
						alt={`User profile avatar of ${user.displayName}`}
						// See https://stackoverflow.com/a/72463692
						referrerPolicy="no-referrer"
					/>
				)}
				<span className="profile-badge-name">
					{user?.displayName ?? "User"}
				</span>
			</div>
			<ProfileModal
				profile={profileData}
				isOpen={isProfileModalOpen}
				onClose={handleCloseProfileModal}
			/>
		</>
	);
}

interface ProfileProps {
	profile?: ProfileData;
	// TODO: Maybe find a way to avoid repeating Modal props on everything that extends it
	isOpen: boolean;
	onClose: () => void;
}

function ProfileModal({ profile, isOpen, onClose }: ProfileProps) {
	const resumeEditorRef = useRef<MDXEditorMethods>(null);
	const { logout } = useContext(AuthContext) as AuthContextType;
	const navigate = useNavigate();

	// Assume this is being used within an AuthProvider
	const authContext = useContext(AuthContext) as AuthContextType;
	// Assume the user is authenticated
	const user = authContext.user as User;

	const {
		isLoading,
		data: resumeQueryData,
		error: resumeQueryError
	} = useQuery({
		queryKey: ["user", user.uid, "resume"],
		queryFn: () => {
			const apiClient = new ApiInterface(API_HTTP.resumeAnalyzer, user);
			return apiClient.get(resumeAnalyzerApiEndpoints.resume);
		}
	});

	useEffect(() => {
		// TODO: Show a message if there is an error retrieving the resume
		if (resumeQueryData) {
			resumeEditorRef.current?.setMarkdown(resumeQueryData.resume ?? "");
		}
	}, [resumeQueryData, resumeQueryError]);

	// Utility function to expand each column in the "Skills" section
	const populateKsaItems = (items: string[]) => {
		return items.map((item) => (
			<div key={item} className="profilemodal-ksa-item">
				{item}
			</div>
		));
	};

	const logoutHandler = () => {
		logout().then(() => navigate("/login"));
	};

	return (
		<Modal
			styleClass="profilemodal"
			isOpen={isOpen}
			onClose={onClose}
			hasCloseButton={!!profile}
		>
			{profile && (
				<>
					<div className="profilemodal-section-resume">
						<div className="mt-4">
							<button
								type="button"
								className="mx-auto w-24 block p-1 text-white font-semibold bg-red-600 rounded"
								onClick={logoutHandler}
							>
								Log Out
							</button>
						</div>
						<div className="profilemodal-section-info hidden">
							<h2>{profile.name}</h2>
							{profile.title && <div>{profile.title}</div>}
							{profile.address &&
								profile.city &&
								profile.state &&
								profile.zipCode && (
									<>
										<div>{profile.address}</div>
										<div>
											<span>{profile.city}</span>, <span>{profile.state}</span>{" "}
											<span>{profile.zipCode}</span>
										</div>
									</>
								)}
							<div className="profilemodal-email">
								<span className="material-symbols-outlined">mail</span>
								<span>{profile.email}</span>
							</div>
							<div className="profilemodal-phone">
								<span className="material-symbols-outlined">call</span>
								<span>{profile.phone}</span>
							</div>
							{profile.linkedinUrl && (
								<a href={profile.linkedinUrl.toString()}>
									{profile.linkedinUrl.toString()}
								</a>
							)}
						</div>
						<div className="profilemodal-fileflowContainer">
							<ResumeFileInput />
						</div>
						<div className="mdxEditorContainer">
							<MDXEditor
								className="profilemodal-mdxeditor border border-gray-200 rounded-lg"
								ref={resumeEditorRef}
								markdown={resumeQueryData?.resume ?? "Resume loading..."}
								readOnly={isLoading}
								contentEditableClassName="prose max-w-full profilemodal-resume-scroll"
								onError={(error) => console.error(error)}
								plugins={[
									headingsPlugin(),
									listsPlugin(),
									linkPlugin(),
									linkDialogPlugin(),
									quotePlugin(),
									thematicBreakPlugin(),
									toolbarPlugin({
										toolbarContents: () => (
											<>
												<UndoRedo />
												<BoldItalicUnderlineToggles />
												<BlockTypeSelect />
												<ListsToggle />
												<CreateLink />
												<InsertThematicBreak />
											</>
										)
									})
								]}
							/>
						</div>
					</div>
					<div className="profilemodal-section-ksa hidden">
						<h3>Skills</h3>
						<div className="profilemodal-ksa-columns-container">
							<div className="profilemodal-ksa-column">
								{populateKsaItems(profile.ksa.knowledge)}
							</div>
							<div className="profilemodal-ksa-column">
								{populateKsaItems(profile.ksa.skills)}
							</div>
							<div className="profilemodal-ksa-column">
								{populateKsaItems(profile.ksa.abilities)}
							</div>
						</div>
					</div>
					<ToastContainer
						containerId="toast-profile"
						position="top-center"
						theme="colored"
						autoClose={false}
					/>
				</>
			)}
		</Modal>
	);
}

export type { ProfileData };

export { ProfileBadge, ProfileModal };
