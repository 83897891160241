import React from "react";
import TimelineEntry from "./TimelineEntry";
import "./Timeline.css";

interface TimelineProps {
	events: TimelineEntry[];
}
const Timeline = ({ events }: TimelineProps) => {
	const getRelativeTime = (timestamp: number) => {
		const now = Math.floor(Date.now() / 1000);
		const seconds = now - timestamp;
		const minutes = Math.floor(seconds / 60);
		const hours = Math.floor(minutes / 60);
		const days = Math.floor(hours / 24);
		const months = Math.floor(days / 30.44); // Average number of days in a month
		const years = Math.floor(days / 365.25); // Account for leap years
		if (seconds < 45) return "a few seconds ago";
		if (seconds < 90) return "a minute ago";
		if (minutes < 45) return `${minutes} minutes ago`;
		if (minutes < 90) return "an hour ago";
		if (hours < 22) return `${hours} hours ago`;
		if (hours < 36) return "a day ago";
		if (days < 26) return `${days} days ago`;
		if (days < 46) return "a month ago";
		if (days < 320) return `${months} months ago`;
		if (days < 548) return "a year ago";
		return `${years} years ago`;
	};
	// This sort algorithm displays most recent first
	var sortedArray: TimelineEntry[] = events.sort(
		(e2, e1) => e1.eventTime - e2.eventTime
	);
	return (
		<ul className="timeline">
			{sortedArray.map((event, index) => (
				<li key={index}>
					<div className={index % 2 === 0 ? "direction-r" : "direction-l"}>
						<div className="flag-wrapper">
							<span className="flag">{event.eventName}</span>
							<span className="time-wrapper">
								<span className="time">{getRelativeTime(event.eventTime)}</span>
							</span>
						</div>
						<div className="desc">{event.eventDescription}</div>
					</div>
				</li>
			))}
		</ul>
	);
};

export default Timeline;
