import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext, useRef, useState } from "react";
import Dropzone, { DropzoneRef, FileRejection } from "react-dropzone";
import ApiInterface from "../services/api-client/api-interface";
import {
	API_HTTP,
	resumeAnalyzerApiEndpoints
} from "../services/api-client/endpoints";
import { toast } from "react-toastify";
import { AuthContext, AuthContextType } from "../context/AuthProvider";
import { User } from "firebase/auth";
import "./ResumeFileInput.css";

// Avoid using keys with "*" because it may cause incorrect output from detectMIMETypeFromExtension()
const RESUME_MIME_TYPES: { [key: string]: string[] } = {
	"application/pdf": [".pdf"],
	"text/plain": [".txt"],
	"text/markdown": [".md", ".markdown"]
};

function ResumeFileInput() {
	const dropzoneRef = useRef<DropzoneRef | null>(null);

	const [resumeFile, setResumeFile] = useState<File>();
	const [resumeURL, setResumeURL] = useState<string>();
	const [errorMessages, setErrorMessages] = useState<string[]>([]);
	const [isDraggingOver, setIsDraggingOver] = useState<boolean>(false);

	const queryClient = useQueryClient();

	// Assume this is being used within an AuthProvider
	const authContext = useContext(AuthContext) as AuthContextType;
	// Assume the user is authenticated
	const user = authContext.user as User;

	const detectMIMETypeFromExtension = (file: File) => {
		const extension = file.name.substring(file.name.lastIndexOf("."));
		for (const mimeType of Object.keys(RESUME_MIME_TYPES)) {
			if (RESUME_MIME_TYPES[mimeType].includes(extension)) {
				return mimeType;
			}
		}
		// Return an empty string if the MIME type is unknown (this shoud never happen)
		return "";
	};

	const resumeMutation = useMutation({
		mutationFn: (resume: File) => {
			const apiClient = new ApiInterface(API_HTTP.resumeAnalyzer, user);
			const formData = new FormData();
			// Fall back to identifying the MIME type from the file extensions if the browser can't detect it
			if (!resume.type) {
				resume = new File([resume], resume.name, {
					type: detectMIMETypeFromExtension(resume),
					lastModified: resume.lastModified
				});
			}
			formData.append("resume", resume);
			return apiClient.post(
				resumeAnalyzerApiEndpoints.resume,
				formData,
				{},
				{
					headers: { "Content-Type": "multipart/form-data" },
					// Disable axios timeout
					// TODO: Change this back to a reasonable timeout later
					timeout: 0
				}
			);
		}
	});

	const getDropzoneStyle = (isDraggingOver: boolean) => {
		const OnDrag = isDraggingOver ? "dropzone-onDrag" : "dropzone-idle";
		return `dropzone-base ${OnDrag}`;
	};

	const clearFile = () => {
		setResumeFile(undefined);
		URL.revokeObjectURL(resumeURL!);
		setResumeURL(undefined);
	};

	const acceptDrop = async (acceptedFiles: File[]) => {
		setResumeFile(acceptedFiles[0]);
		// Convert file to Blob
		const fileString = await new Promise<string>((resolve, reject) => {
			const reader = new FileReader();
			reader.onerror = () => reject(reader.error);
			reader.onload = () => resolve(reader.result as string);
			reader.readAsArrayBuffer(acceptedFiles[0]);
		});
		setResumeURL(URL.createObjectURL(new Blob([fileString])));
		setErrorMessages([]);
	};

	const rejectDrop = (fileRejections: FileRejection[]) => {
		setErrorMessages(
			fileRejections.map((fileRejection) => {
				const fileErrors = fileRejection.errors.map((error) => error.message);
				return `${fileRejection.file.name}: ${fileErrors.join(", ")}`;
			})
		);
		clearFile();
	};

	const uploadResume = () => {
		resumeMutation.mutate(resumeFile!, {
			onSuccess: (response) => {
				// Directly update the react-query cache here to refresh the resume visualization area
				// See https://tkdodo.eu/blog/mastering-mutations-in-react-query#direct-updates
				queryClient.setQueryData(["user", user.uid, "resume"], {
					resume: response.resumeMarkdown
				});
				// Indicate success
				toast.success("Your resume has been processed!", {
					containerId: "toast-profile",
					className: "w-80"
				});
				clearFile();
			},
			onError: (error: any) => {
				// Ignore 504 Gateway Timeout errors
				if (error.response?.status === 504) {
					// Indicate that processing hasn't necessarily failed but is taking longer than expected
					toast.info(
						<p>
							Your resume is being processed.
							<br />
							Please check back again later
						</p>,
						{
							containerId: "toast-profile"
						}
					);
					clearFile();
					return;
				}
				// Indicate error
				toast.error(
					"Something went wrong when processing your resume, please try again",
					{
						containerId: "toast-profile"
					}
				);
			}
		});
	};

	return (
		<form
			onSubmit={(event) => {
				event.preventDefault();
				uploadResume();
			}}
		>
			<div className="resumeFileInputHdr">
				<div className="resumeFileInputHdr-l">
					<button
						className="resumeFileInputBtn"
						type="button"
						onClick={() => dropzoneRef.current!.open()}
					>
						<span className="material-symbols-outlined py-2">attach_file</span>
					</button>
				</div>
				<div className="resumeFileInputHdr-r">
					<Dropzone
						ref={dropzoneRef}
						noClick={true}
						maxFiles={1}
						maxSize={1000000000}
						accept={RESUME_MIME_TYPES}
						onDragEnter={() => setIsDraggingOver(true)}
						onDragLeave={() => setIsDraggingOver(false)}
						onDrop={() => setIsDraggingOver(false)}
						onDropAccepted={acceptDrop}
						onDropRejected={rejectDrop}
					>
						{({ getRootProps, getInputProps }) => (
							<div
								className={getDropzoneStyle(isDraggingOver)}
								{...getRootProps()}
							>
								<input {...getInputProps()} />
								<p key="instruction-message" className="m-0 font-bold">
									Drag and drop your resume here
								</p>
								{!!resumeFile && (
									<>
										<a
											className="text-blue-500 text-lg hover:underline"
											href={resumeURL}
											download={resumeFile.name}
										>
											{resumeFile.name}
										</a>
										{!resumeMutation.isPending && (
											<>
												&nbsp;
												<button
													onClick={clearFile}
													type="button"
													className="material-symbols-outlined relative top-0.5 text-base aspect-square w-6 text-red-400 rounded border border-red-400"
												>
													delete
												</button>
											</>
										)}
									</>
								)}
								{!!errorMessages.length &&
									errorMessages.map((errorMessage) => (
										<p key={errorMessage} className="text-red-400">
											{errorMessage}
										</p>
									))}
							</div>
						)}
					</Dropzone>
				</div>
			</div>
			{!!resumeFile && (
				<div className="mt-[20px]">
					{resumeMutation.isPending && (
						<p className="text-center">
							Processing... Please wait, this may take a minute
						</p>
					)}
					<button
						disabled={resumeMutation.isPending}
						className="relative uppercase font-semibold rounded-md text-white bg-green-500 mt-[20px] py-1 mx-auto w-28 block disabled:bg-green-200 disabled:text-transparent"
					>
						Upload
						{resumeMutation.isPending && (
							<div
								role="status"
								className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center"
							>
								<svg
									aria-hidden="true"
									className="w-6 h-6 text-white animate-spin fill-cyan-300"
									viewBox="0 0 100 101"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
										fill="currentColor"
									/>
									<path
										d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
										fill="currentFill"
									/>
								</svg>
								<span className="sr-only">Loading...</span>
							</div>
						)}
					</button>
				</div>
			)}
		</form>
	);
}

export { ResumeFileInput };
