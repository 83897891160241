import { useContext, useEffect } from "react";
import { AuthContext, AuthContextType } from "../context/AuthProvider";
import { Navigate, Outlet } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import ApiInterface from "../services/api-client/api-interface";
import {
	API_HTTP,
	resumeAnalyzerApiEndpoints
} from "../services/api-client/endpoints";
import { toast } from "react-toastify";

interface PrivateRouteWrapperProps {
	routePermission?: string;
	fallbackPath?: string;
}

const PrivateRouteWrapper = ({
	routePermission,
	fallbackPath = "/newlanding"
}: PrivateRouteWrapperProps) => {
	// Assume this is being used within an AuthProvider
	const { loading: authLoading, user } = useContext(
		AuthContext
	) as AuthContextType;
	const {
		isLoading: queryLoading,
		data: permissions,
		isError: isQueryError,
		isFetchedAfterMount
	} = useQuery({
		queryKey: ["user", user?.uid, "permissions"],
		queryFn: () => {
			if (user?.uid) {
				// TODO: Investigate what causes user.uid to be "0"
				// 	Is this working as intended?
				const apiClient = new ApiInterface(API_HTTP.resumeAnalyzer, user);
				return apiClient
					.get(resumeAnalyzerApiEndpoints.permissions)
					.then((data) => data.permissions)
					.catch((err) => {
						// A 403 error is equivalent to having no permissions
						if (err.status === 403) {
							return [];
						} else {
							throw err;
						}
					});
			} else {
				return [];
			}
		}
	});

	useEffect(() => {
		if (isQueryError) {
			toast.error(
				<span>
					An error occurred during authentication.
					<br />
					Please check your network connection and reload the page.
				</span>,
				{
					containerId: "toast-main",
					toastId: "permissions-error",
					position: "top-center",
					theme: "colored",
					autoClose: false
				}
			);
		}
	}, [isQueryError]);

	// Checking isFetchedAfterMount forces the component to always use fresh data
	if (authLoading || queryLoading || isQueryError || !isFetchedAfterMount) {
		// TODO: Display a proper loading message while waiting for authentication
		return <span>Authenticating...</span>;
	} else if (!user) {
		// Redirect to login page if user is not authenticated
		return <Navigate to="/login" />;
	} else if (routePermission && !permissions?.includes(routePermission)) {
		// Redirect to the fallback path (default is "newlanding") if user has insufficient permissions
		return <Navigate to={fallbackPath} />;
	} else {
		// Render the route(s)
		return <Outlet />;
	}
};

export default PrivateRouteWrapper;
