import React, { useContext, useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

// Import types for dnd
import type {
	DraggableLocation,
	DroppableId,
	DropResult,
	ResponderProvided
} from "@hello-pangea/dnd";

// Local imports
import "./index.css";
import AddJobModal from "./addJobModal/addJobModal";
import JobDetailsModal from "./JobDetailsModal";
import {
	API_HTTP,
	resumeAnalyzerApiEndpoints
} from "./services/api-client/endpoints";
import ApiInterface from "./services/api-client/api-interface";
import Navbar from "./components/navbar";
import { AuthContext, AuthContextType } from "./context/AuthProvider";
import { User } from "firebase/auth";
import LoadingWheel from "./components/LoadingWheel";
import SwimlanesDirections from "./components/SwimlanesDirections";

const APPLIED = "applied";
const CONFIRMED = "confirmed";
const RECRUITERINTERVIEW = "recruiterinterview";
const HIRINGMANAGERINTERVIEW = "hiringmanagerinterview";
const DONE = "done";

const JOB_STATE_CHANGE_TIMEOUT_MS = 4000;

interface JobPostingData {
	id: string;
	title: string;
	jobDescription: string;
	company: string;
	state: string;
	order: number;
}

type JobStateChange = {
	jobId: number;
	newState: string;
	newOrder: number;
};

// Utility function to reorder the swimlane columns after moving a job posting
const move = (
	source: JobPostingData[],
	destination: JobPostingData[],
	droppableSource: DraggableLocation,
	droppableDestination: DraggableLocation
) => {
	const sourceIsDestination =
		droppableSource.droppableId === droppableDestination.droppableId;
	const sourceClone = [...source];
	const destClone = sourceIsDestination ? sourceClone : [...destination];
	const [removed] = sourceClone.splice(droppableSource.index, 1);

	destClone.splice(droppableDestination.index, 0, removed);

	return {
		[droppableSource.droppableId]: sourceClone,
		[droppableDestination.droppableId]: destClone
	};
};

function AddJobButton({
	handleClick,
	disabled,
	tooltip
}: {
	handleClick: () => void;
	disabled?: boolean;
	tooltip?: string;
}) {
	return (
		<button
			className="add-job-button"
			disabled={disabled}
			onClick={handleClick}
			title={tooltip}
		>
			<span className="material-symbols-outlined">add</span>
		</button>
	);
}

interface CardProps {
	posting: JobPostingData;
	index: number;
}

function DNDCard({ posting, index }: CardProps): React.ReactElement {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	return (
		<Draggable
			draggableId={posting.id}
			index={index}
			isDragDisabled={isModalOpen}
		>
			{(provided, snapshot) => (
				<div
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					style={provided.draggableProps.style}
					className={["card", snapshot.isDragging ? " card-dragging" : ""].join(
						""
					)}
					onClick={() => !isModalOpen && setIsModalOpen(true)}
				>
					<h4 className="card-company">{posting.company}</h4>
					<p className="card-title">{posting.title}</p>
					<JobDetailsModal
						job={posting}
						isOpen={isModalOpen}
						onClose={() => setIsModalOpen(false)}
					/>
				</div>
			)}
		</Draggable>
	);
}

interface ColumnProps {
	droppableId: DroppableId;
	title: string;
	count: number;
	button?: React.JSX.Element;
	children?: React.ReactNode;
}

function DNDColumn({
	droppableId,
	title,
	count,
	button,
	children
}: ColumnProps): React.ReactElement {
	return (
		<div className="swimlane">
			<div className="colheader">
				<h3>{title}</h3>
				<div className="colheader-container">
					{button}
					<div className="cardcount">{count}</div>
				</div>
			</div>
			<Droppable droppableId={droppableId}>
				{(provided, snapshot) => (
					<div
						className={[
							"swimlane-droparea",
							snapshot.isDraggingOver ? " swimlane-droparea-draggingover" : ""
						].join("")}
						{...provided.droppableProps}
						ref={provided.innerRef}
					>
						{children}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</div>
	);
}

function DoneColumn(): React.ReactElement {
	return (
		<Droppable droppableId={DONE}>
			{(provided, snapshot) => (
				<div
					className={`swimlane-done${snapshot.isDraggingOver ? " swimlane-done-draggingover" : ""}`}
				>
					<div className="swimlane-done-icon">
						<span className="material-symbols-outlined">scan_delete</span>
					</div>
					<div
						className="swimlane-done-droparea"
						{...provided.droppableProps}
						ref={provided.innerRef}
					>
						{provided.placeholder}
					</div>
				</div>
			)}
		</Droppable>
	);
}

interface SwimlanesState {
	[APPLIED]: JobPostingData[];
	[CONFIRMED]: JobPostingData[];
	[RECRUITERINTERVIEW]: JobPostingData[];
	[HIRINGMANAGERINTERVIEW]: JobPostingData[];
	[key: string]: JobPostingData[];
}

function Swimlanes() {
	// Assume this is being used within an AuthProvider
	const authContext = useContext(AuthContext) as AuthContextType;
	// Assume the user is authenticated
	const user = authContext.user as User;

	// Check if the user has a resume so we know whether to enable the add job button
	const queryClient = useQueryClient();
	const { data: resumeQueryData } = useQuery({
		queryKey: ["user", user.uid, "resume"],
		// Don't bother duplicating the query function, it won't be executed
		queryFn: (): any => {},
		enabled: false
	});
	const foundCachedResume = !!resumeQueryData?.resume;
	const addJobButtonTooltip = foundCachedResume
		? ""
		: resumeQueryData
			? "Upload a resume first!"
			: "Loading...";

	// State

	const [postings, setPostings] = useState<SwimlanesState>({
		[APPLIED]: [],
		[CONFIRMED]: [],
		[RECRUITERINTERVIEW]: [],
		[HIRINGMANAGERINTERVIEW]: []
	});

	const {
		isLoading: jobsQueryLoading,
		data: jobsQueryData,
		error: jobsQueryError
	} = useQuery({
		queryKey: ["user", user.uid, "jobs"],
		queryFn: () => {
			const apiClient = new ApiInterface(API_HTTP.resumeAnalyzer, user);
			return apiClient.get(resumeAnalyzerApiEndpoints.jobs);
		}
	});

	const [stateChangeTimeoutId, setStateChangeTimeoutId] = useState<
		NodeJS.Timeout | undefined
	>();
	const [stateChangeQueue, setStateChangeQueue] = useState<{
		waiting: JobStateChange[];
		sent: JobStateChange[];
	}>({ waiting: [], sent: [] });

	const stateChangeTimeoutIdRef = useRef(stateChangeTimeoutId);
	const stateChangeQueueRef = useRef(stateChangeQueue);

	const jobStateMutation = useMutation({
		mutationFn: (stateChanges: JobStateChange[]): Promise<any> => {
			const apiClient = new ApiInterface(API_HTTP.resumeAnalyzer, user);
			return apiClient.patch(resumeAnalyzerApiEndpoints.jobs, { stateChanges });
		},
		onSuccess: (): void => {
			// Clear the sent queue
			const newStateChangeQueue = {
				...stateChangeQueueRef.current,
				sent: []
			};
			setStateChangeQueue(newStateChangeQueue);
			// Update ref for onSettled callback
			stateChangeQueueRef.current = newStateChangeQueue;
		},
		onSettled: (): void => {
			// If there are waiting state changes with no timeout, trigger another mutation
			if (
				!stateChangeTimeoutIdRef.current &&
				stateChangeQueueRef.current.waiting.length
			) {
				doJobStateMutation();
			}
		}
	});

	const jobStateMutationStatusRef = useRef<
		"error" | "idle" | "pending" | "success"
	>(jobStateMutation.status);

	// Effects

	useEffect(() => {
		// Update reference to status of jobStateMutation
		jobStateMutationStatusRef.current = jobStateMutation.status;
	}, [jobStateMutation.status]);

	useEffect(() => {
		// Update reference to the state change queue
		stateChangeQueueRef.current = stateChangeQueue;
	}, [stateChangeQueue]);

	useEffect(() => {
		// Update reference to the state change timeout ID
		stateChangeTimeoutIdRef.current = stateChangeTimeoutId;
	}, [stateChangeTimeoutId]);

	useEffect(() => {
		let jobs: JobPostingData[] = [];

		if (jobsQueryError) {
			console.error(jobsQueryError);
			setTimeout(() => alert("The API could not be reached"), 100);
		}

		if (jobsQueryData) {
			// Convert to the correct type
			jobs = jobsQueryData.jobs.map((job: any) => {
				return {
					id: `${job.jobId}`,
					state: job.state,
					title: job.title,
					company: job.company,
					order: job.order,
					jobDescription: ""
				};
			});
		}

		// Sort jobs into the correct columns
		// Use reduce() here because this version of TypeScript doesn't have groupBy()
		// See https://github.com/microsoft/TypeScript/issues/47171
		const swimlanesState = jobs.reduce(
			(stateObj, job) => {
				stateObj[job.state]?.push(job) ?? (stateObj[job.state] = [job]);
				return stateObj;
			},
			{
				[APPLIED]: [],
				[CONFIRMED]: [],
				[RECRUITERINTERVIEW]: [],
				[HIRINGMANAGERINTERVIEW]: []
			} as SwimlanesState
		);

		// Sort the contents of each swimlane column by the job posting "order" property
		for (let column in swimlanesState) {
			swimlanesState[column].sort((jobA, jobB) => jobA.order - jobB.order);
		}

		setPostings(swimlanesState);
	}, [jobsQueryData, jobsQueryError]);

	const [isAddJobModalOpen, setAddJobModalOpen] = useState<boolean>(false);

	// Functions

	const enqueueJobStateChange = (stateChange: JobStateChange): void => {
		// Add a state change to the waiting queue
		setStateChangeQueue((queue) => ({
			...queue,
			waiting: [...queue.waiting, stateChange]
		}));
		// Clear any existing previous timeout
		if (stateChangeTimeoutId) {
			clearTimeout(stateChangeTimeoutId);
		}
		const stateChangeCallback = () => {
			// Check for pending requests and trigger a mutation if there are none
			if (jobStateMutationStatusRef.current !== "pending") {
				doJobStateMutation();
			}
			// Delete the timeout ID
			setStateChangeTimeoutId(undefined);
		};
		// Set timeout for triggering mutation
		setStateChangeTimeoutId(
			setTimeout(stateChangeCallback, JOB_STATE_CHANGE_TIMEOUT_MS)
		);
	};

	const doJobStateMutation = (): void => {
		const { sent, waiting } = stateChangeQueueRef.current;
		// Transfer waiting queue into sent queue
		const mutationData = [...sent, ...waiting];
		setStateChangeQueue({ waiting: [], sent: mutationData });
		// Trigger mutation
		jobStateMutation.mutate(mutationData);
	};

	const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
		const { source, destination } = result;

		// dropped outside the list
		if (!destination) {
			return;
		}

		// position has not changed
		if (
			source.droppableId === destination.droppableId &&
			source.index === destination.index
		) {
			return;
		}

		const movedPosting = postings[source.droppableId][source.index];

		if (destination.droppableId === DONE) {
			const sourceClone = [...postings[source.droppableId]];
			sourceClone.splice(movedPosting.order, 1);
			sourceClone.forEach((job, index) => (job.order = index));
			movedPosting.state = DONE;
			movedPosting.order = 0;
			setPostings({ ...postings, [source.droppableId]: sourceClone });
		} else {
			// "Physically" move the job posting
			const moveResult = move(
				postings[source.droppableId],
				postings[destination.droppableId],
				source,
				destination
			);

			// Update the job posting's state based on the destination column
			movedPosting.state = destination.droppableId;

			// Update the order of affected job postings to reflect their new positions
			moveResult[source.droppableId].forEach(
				(job, index) => (job.order = index)
			);
			moveResult[destination.droppableId].forEach(
				(job, index) => (job.order = index)
			);

			// Update Swimlanes state
			setPostings({
				...postings,
				...moveResult
			});
		}

		// TODO: Update order of affected job postings in cache too?
		// Optimistic cache update
		queryClient.setQueryData(
			["user", user.uid, "jobs"],
			({ jobs }: { jobs: any[] }): { jobs: any[] } => {
				// Find the moved job in the cache
				const cachedJobIndex = jobs.findIndex(
					({ jobId }) => jobId === parseInt(movedPosting.id)
				);
				// If found, change its state/order
				if (cachedJobIndex !== -1) {
					jobs[cachedJobIndex].state = movedPosting.state;
					jobs[cachedJobIndex].order = movedPosting.order;
				}
				return { jobs };
			}
		);

		// Enqueue a job state change
		enqueueJobStateChange({
			jobId: parseInt(movedPosting.id),
			newState: movedPosting.state,
			newOrder: movedPosting.order
		});
	};

	// Update open/closed state of AddJobModal
	const handleOpenAddJobModal = () => {
		setAddJobModalOpen(true);
	};

	const handleCloseAddJobModal = () => {
		setAddJobModalOpen(false);
	};

	const populateCards = (jobPostings: JobPostingData[]) => {
		return jobPostings.map((posting, index) => (
			<DNDCard key={posting.id} posting={posting} index={index} />
		));
	};

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<DNDColumn
				title="Applied"
				droppableId={APPLIED}
				count={postings[APPLIED].length}
				button={
					<AddJobButton
						handleClick={handleOpenAddJobModal}
						disabled={
							jobsQueryLoading || !!jobsQueryError || !foundCachedResume
						}
						tooltip={addJobButtonTooltip}
					/>
				}
			>
				{jobsQueryLoading ? (
					<LoadingWheel
						svgClasses={["text-gray-200", "fill-gray-300", "w-9"]}
					/>
				) : (
					populateCards(postings[APPLIED])
				)}
			</DNDColumn>
			<DNDColumn
				title="Confirmed"
				droppableId={CONFIRMED}
				count={postings[CONFIRMED].length}
			>
				{jobsQueryLoading ? (
					<LoadingWheel
						svgClasses={["text-gray-200", "fill-gray-300", "w-9"]}
					/>
				) : (
					populateCards(postings[CONFIRMED])
				)}
			</DNDColumn>
			<DNDColumn
				title="Recruiter Interview"
				droppableId={RECRUITERINTERVIEW}
				count={postings[RECRUITERINTERVIEW].length}
			>
				{jobsQueryLoading ? (
					<LoadingWheel
						svgClasses={["text-gray-200", "fill-gray-300", "w-9"]}
					/>
				) : (
					populateCards(postings[RECRUITERINTERVIEW])
				)}
			</DNDColumn>
			<DNDColumn
				title="Hiring Manager Interview"
				droppableId={HIRINGMANAGERINTERVIEW}
				count={postings[HIRINGMANAGERINTERVIEW].length}
			>
				{jobsQueryLoading ? (
					<LoadingWheel
						svgClasses={["text-gray-200", "fill-gray-300", "w-9"]}
					/>
				) : (
					populateCards(postings[HIRINGMANAGERINTERVIEW])
				)}
			</DNDColumn>
			<DoneColumn />
			<AddJobModal
				isOpen={isAddJobModalOpen}
				onClose={handleCloseAddJobModal}
			/>
		</DragDropContext>
	);
}

function SwimlanesPage() {
	return (
		<React.StrictMode>
			<Navbar />
			<SwimlanesDirections />
			<main className="max-w-7xl mx-auto grid justify-items-stretch justify-content-center">
				<div className="flex flex-row flex-nowrap gap-5 justify-center py-12">
					<Swimlanes />
				</div>
			</main>
		</React.StrictMode>
	);
}

export { APPLIED, CONFIRMED, RECRUITERINTERVIEW, HIRINGMANAGERINTERVIEW };

export type { JobPostingData };

export { DNDCard };

export default SwimlanesPage;
