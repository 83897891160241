import { useContext } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { User } from "firebase/auth";
import { AuthContext, AuthContextType } from "../context/AuthProvider";
import "./SwimlanesDirections.css";

const SWIMLANES_FTUE_FLAG = "ftue/swimlanes";

export default function SwimlanesDirections() {
	// Assume this is being used within an AuthProvider
	const authContext = useContext(AuthContext) as AuthContextType;
	// Assume the user is authenticated
	const user = authContext.user as User;

	const queryClient = useQueryClient();
	const cachedPermissions: string[] =
		queryClient.getQueryData(["user", user?.uid, "permissions"]) ?? [];
	const ftueFlag = cachedPermissions.includes(SWIMLANES_FTUE_FLAG);

	return ftueFlag ? (
		<div className="swimlanes-directions">
			<h2>How to use</h2>
			<ol>
				<li>
					✂️ Cut the job posting and click add{" "}
					<span className="material-symbols-outlined example-add-button">
						add
					</span>
					. Paste the posting, then enter the company and position.
				</li>
				<li>
					🚀 Wait for the documentation to be created, and cut-and-paste the
					cover letter into your job application.
				</li>
				<li>🎉 Move the job across the map as you see your success.</li>
			</ol>
		</div>
	) : (
		<></>
	);
}
