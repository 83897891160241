import React, { useContext } from "react";
import "./PricingCard.css";
import { AuthContext, AuthContextType } from "../../context/AuthProvider";
import { GoogleAuthProvider } from "firebase/auth";
import { auth } from "../../services/firebase/firebase";
import "../Analytics/Analytics";
import Analytics from "../Analytics/Analytics";

interface PricingCardProps {
	title: string;
	price: number;
	description: string;
	features: string[];
	vendorId: string;
	paymentLinkUrl: string;
	campaignId?: number;
}

const PricingCard: React.FC<PricingCardProps> = ({
	title,
	price,
	description,
	features,
	vendorId,
	paymentLinkUrl,
	campaignId
}) => {
	const { loading, login, user } = useContext(AuthContext) as AuthContextType;

	const enforceLogin = async (): Promise<void> => {
		if (loading || !user) {
			const googleProvider = new GoogleAuthProvider();
			googleProvider.setCustomParameters({
				prompt: "select_account"
			});
			await login(googleProvider);
		}
	};

	async function clickHandler(event: any): Promise<void> {
		console.log(`Pricing CTA called for ${vendorId}`);
		try {
			// Make sure the user is logged in before redirecting
			await enforceLogin();
		} catch (err: any) {
			// Do not redirect if the user closes the login popup
			if (
				err.code === "auth/popup-closed-by-user" ||
				err.code === "auth/cancelled-popup-request"
			) {
				return;
			} else {
				throw err;
			}
		}
		// TODO: Make this better
		// Slightly hackish to bypass our own AuthContext here, but if there were no errors,
		//	it's probably safe to assume that auth.currentUser is valid.
		//	The currentUser may have been changed by enforceLogin() since the UI was rendered,
		//	so the "user" from the AuthContext is out of date.
		const { currentUser } = auth;
		// Construct redirect URL using payment link
		const stripeUrl = new URL(paymentLinkUrl);
		if (currentUser) {
			stripeUrl.searchParams.set("client_reference_id", currentUser.uid);
			stripeUrl.searchParams.set("prefilled_email", currentUser.email ?? "");
		}
		if (campaignId) {
			stripeUrl.searchParams.set("utm_campaign", campaignId.toString());
		}
		// Redirect to Stripe
		window.location.href = stripeUrl.toString();
	}

	return (
		<div className="pricing-card">
			<div className="glint-effect">
				<div className="pricing-card-content">
					<h2 className="pricing-card-title">{title}</h2>
					<div className="pricing-card-price">
						${price.toFixed(2)}
						<span className="pricing-card-price-period">/year</span>
					</div>
					<p className="pricing-card-description">{description}</p>
					<ul className="pricing-card-features">
						{features.map((feature, index) => (
							<li key={index} className="pricing-card-feature">
								<svg
									className="pricing-card-feature-icon"
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M5 13l4 4L19 7"
									></path>
								</svg>
								{feature}
							</li>
						))}
					</ul>
					<Analytics
						measurementId="G-M5FQF9YL53"
						analyticsCat="Purchase"
						analyticsLbl={vendorId}
					>
						<button className="pricing-card-button" onClick={clickHandler}>
							Get now
						</button>
					</Analytics>
				</div>
			</div>
		</div>
	);
};

export default PricingCard;
