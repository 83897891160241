export enum API_HTTP {
	resumeAnalyzer = "https://resume-analyzer-api-gw-7xzqkadx.uc.gateway.dev"
	//resumeAnalyzer = "http://localhost:8080/" for local testing only
}

export enum resumeAnalyzerApiEndpoints {
	jobs = "/user/jobs", // get/post/delete/put
	jobDetails = "/user/jobs/{jobId}", // get
	resume = "/user/resume", //get/post
	coverLetter = "/user/cover-letter", // get/post
	permissions = "/user/permissions", //get
	orders = "/user/orders" // post
}
