import { useEffect } from "react";

const HOTJAR_ID = "5204602";

const Hotjar: React.FC = () => {
	useEffect(() => {
		const script = document.createElement("script");
		script.innerHTML = `
			(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${HOTJAR_ID},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    	})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
		`;
		document.head.appendChild(script);
		// Remove script on component unmount
		return () => {
			document.head.removeChild(script);
		};
	}, []);
	return null;
};

export default Hotjar;
