import { useEffect } from "react";

const LINKEDIN_PARTNER_ID = "7931617";

export const Linkedin: React.FC = () => {
	useEffect(() => {
		const script = document.createElement("script");
		script.innerHTML = `

_linkedin_partner_id = "${LINKEDIN_PARTNER_ID}";
window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
window._linkedin_data_partner_ids.push(_linkedin_partner_id);

(function(l) {
if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
window.lintrk.q=[]}
var s = document.getElementsByTagName("script")[0];
var b = document.createElement("script");
b.type = "text/javascript";b.async = true;
b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
s.parentNode.insertBefore(b, s);})(window.lintrk);

		`;
		document.head.appendChild(script);
		// Remove script on component unmount
		return () => {
			document.head.removeChild(script);
		};
	}, []);
	return null;
};
