import React from "react";
import "./tabs.css";

interface TabsProps {
	children: JSX.Element[] | JSX.Element;
}

const Tabs = ({ children }: TabsProps) => {
	return (
		<div className="tab-container">
			<ul className="tabs">{children}</ul>
		</div>
	);
};

export default Tabs;
