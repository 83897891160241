import {
	AuthProvider as FirebaseAuthProvider,
	onAuthStateChanged,
	signInWithPopup,
	signOut,
	User,
	UserCredential
} from "firebase/auth";
import { createContext, ReactNode, useEffect, useState } from "react";
import { auth } from "../services/firebase/firebase";

// Built using the following as a guide: https://medium.com/@jackpritomsoren/building-a-firebase-authentication-and-private-route-system-in-a-react-app-98113229ad67

export const AuthContext = createContext<AuthContextType | null>(null);

export type AuthContextType = {
	user: User | null;
	login: (provider: FirebaseAuthProvider) => Promise<UserCredential>;
	logout: () => Promise<void>;
	loading: boolean;
};

const AuthProvider = ({ children }: { children: ReactNode }) => {
	const [user, setUser] = useState<User | null>(null);
	const [loading, setLoading] = useState(true);

	const login = async (provider: FirebaseAuthProvider) => {
		setLoading(true);
		try {
			return await signInWithPopup(auth, provider);
		} finally {
			// Reset loading state here since onAuthStateChanged observer may not fire
			setLoading(false);
		}
	};

	const logout = () => {
		setLoading(true);
		try {
			return signOut(auth);
		} finally {
			// Reset loading state here since onAuthStateChanged observer may not fire
			setLoading(false);
		}
	};

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
			setUser(currentUser);
			setLoading(false);
		});

		return () => {
			unsubscribe();
		};
	}, []);

	const authValue = {
		user,
		login,
		logout,
		loading
	};

	return (
		<AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
	);
};

export default AuthProvider;
