// mdx editor imports
import "@mdxeditor/editor/style.css";
import {
	MDXEditor,
	BoldItalicUnderlineToggles,
	toolbarPlugin,
	UndoRedo,
	headingsPlugin,
	listsPlugin,
	linkPlugin,
	CreateLink,
	ListsToggle,
	BlockTypeSelect,
	quotePlugin,
	thematicBreakPlugin,
	InsertThematicBreak,
	linkDialogPlugin,
	MDXEditorMethods
} from "@mdxeditor/editor";
import RedoAi from "./components/mdxPlugins/RedoAi/RedoAi";

import Modal from "./Modal";
import { JobPostingData } from "./Swimlanes";
import "./JobDetailsModal.css";
import { ReactElement, useContext, useEffect, useRef, useState } from "react";
import Timeline from "./Timeline/Timeline";
import TimelineEntry from "./Timeline/TimelineEntry";
import Tabs from "./tabs/tabs";
import Tab from "./tabs/tab";
import LabeledText from "./labelText/LabeledText";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AuthContext, AuthContextType } from "./context/AuthProvider";
import ApiInterface from "./services/api-client/api-interface";
import {
	API_HTTP,
	resumeAnalyzerApiEndpoints
} from "./services/api-client/endpoints";
import { User } from "firebase/auth";
import LoadingWheel, { LoadingStatus } from "./components/LoadingWheel";

// Example usage of the component with UnixTimeStamp values
let jsonData: TimelineEntry[] = [
	{
		eventName: "Apple Inc.",
		eventTime: 1325376000, // January 1, 2012
		eventDescription:
			"My first employer. All the stuff I've learned and projects I've been working on."
	},
	{
		eventName: "Keys And Castles",
		eventTime: 1425376000, // January 1, 2012
		eventDescription: "Favorite one."
	},
	{
		eventName: "Harvard University",
		eventTime: 1220227200, // September 1, 2008
		eventDescription:
			"A description of all the lectures and courses I have taken and my final degree?"
	},
	{
		eventName: "Freelancer",
		eventTime: 1672531200, // January 1, 2023
		eventDescription:
			"My current employment. Way better than the position before!"
	}
];

interface JobDetailsModalProps {
	job: JobPostingData;
	isOpen: boolean;
	onClose: () => void;
}

function JobDetailsModal({
	job,
	isOpen,
	onClose
}: JobDetailsModalProps): ReactElement {
	// Assume this is being used within an AuthProvider
	const authContext = useContext(AuthContext) as AuthContextType;
	// Assume the user is authenticated
	const user = authContext.user as User;

	const { data: jobDetailsQueryData } = useQuery({
		queryKey: ["user", user.uid, "job", job.id],
		enabled: isOpen,
		queryFn: (): Promise<any> => {
			const apiClient = new ApiInterface(API_HTTP.resumeAnalyzer, user);
			return apiClient.get(resumeAnalyzerApiEndpoints.jobDetails, {
				jobId: job.id
			});
		}
	});

	const queryClient = useQueryClient();
	const coverLetterRegenMutation = useMutation({
		mutationFn: async (): Promise<void> => {
			// Invalidate the cached cover letter
			queryClient.setQueryData(
				["user", user.uid, "job", job.id, "cover-letter"],
				{ coverLetter: null }
			);
			// Update isGenerating flag in cached job details
			queryClient.setQueryData(
				["user", user.uid, "job", job.id],
				(data: any) => ({ ...data, job: { ...data?.job, isGenerating: true } })
			);
			// Generate a new cover letter
			const apiClient = new ApiInterface(API_HTTP.resumeAnalyzer, user);
			return await apiClient.post(
				resumeAnalyzerApiEndpoints.coverLetter,
				{},
				{},
				// Timeout after 2 minutes
				// TODO: Figure out what an appropriate timeout "should" be
				{ params: { jobId: job.id }, timeout: 120 * 1000 }
			);
		},
		onSuccess: ({ coverLetter }: any): void => {
			// Un-set the isGenerating flag
			queryClient.setQueryData(
				["user", user.uid, "job", job.id],
				(data: any) => ({ ...data, job: { ...data?.job, isGenerating: false } })
			);
			// Update cache
			queryClient.setQueryData(
				["user", user.uid, "job", job.id, "cover-letter"],
				{ coverLetter }
			);
		}
	});

	const coverLetterGenerationPending =
		!!jobDetailsQueryData?.isGenerating || coverLetterRegenMutation.isPending;

	const { data: coverLetterQueryData } = useQuery({
		queryKey: ["user", user.uid, "job", job.id, "cover-letter"],
		// Do not fetch the cover letter when the modal is closed or when re-generating
		enabled: isOpen && !coverLetterGenerationPending,
		queryFn: (): Promise<any> => {
			const apiClient = new ApiInterface(API_HTTP.resumeAnalyzer, user);
			return apiClient
				.get(
					resumeAnalyzerApiEndpoints.coverLetter,
					{},
					{ params: { jobId: job.id } }
				)
				.catch((err) => {
					// A 404 error means that the cover letter does not exist (yet)
					if (
						err.name === "AxiosError" &&
						err.code === "ERR_BAD_REQUEST" &&
						err.status === 404
					) {
						return null;
					} else {
						throw err;
					}
				});
		}
	});

	const [currentTabKey, setCurrrentTabKey] = useState<"JD2" | "CL2">("JD2");
	const { editorEnabled, editorContentsSource } = {
		JD2: {
			editorEnabled: false,
			editorContentsSource: jobDetailsQueryData?.job?.jobDescription
		},
		CL2: {
			editorEnabled: true,
			// Do not show cached cover letter if one is being generated
			editorContentsSource: coverLetterGenerationPending
				? null
				: coverLetterQueryData?.coverLetter
		}
	}[currentTabKey];

	const editorRef = useRef<MDXEditorMethods>(null);

	// Keep the actual editor contents up to date with the internal state
	// This allows the editor's contents to be controlled programmatically
	useEffect(() => {
		const editor = editorRef.current;
		if (editor) {
			// TODO: Handle the possibility that editorContentsSource is an empty string, but valid data
			//   Currently an empty string is not a possibility, but it might be in the future
			editor.setMarkdown(editorContentsSource ?? "Loading...");
		}
	}, [editorContentsSource]);

	const clickHandler = (tabData: "JD2" | "CL2"): void => {
		//Step 1. Process the Click from tab
		setCurrrentTabKey(tabData);
	};

	const handleClose = (): void => {
		onClose();
		// Reset current tab to Job Description
		setCurrrentTabKey("JD2");
	};

	const regenCoverLetter = (): void => {
		// Do nothing if the cover letter is loading or mutation is pending
		if (!coverLetterQueryData || coverLetterGenerationPending) {
			return;
		}
		// Regenerate the cover letter
		coverLetterRegenMutation.mutate();
	};

	return (
		<Modal
			styleClass="detailmodal"
			isOpen={isOpen}
			hasCloseButton={true}
			onClose={handleClose}
		>
			{isOpen && (
				<div className="detailmodal-innercontainer">
					<div className="detailmodal-jobid">
						<LabeledText label="Job #:">{job.id}</LabeledText>
					</div>
					<div className="detailmodal-companytitle">
						<LabeledText label="Company:">{job.company}</LabeledText>
					</div>
					<div className="detailmodal-jobtitle">
						<LabeledText label="Job title:">{job.title}</LabeledText>
					</div>
					<div className="detailmodal-contact hidden">(contact)</div>
					<div className="detailmodal-nextsteps hidden">
						(next steps)
						<br />
						State: {job.state}
					</div>
					<div className="detailmodal-tabs">
						<Tabs>
							<Tab onClick={() => clickHandler("JD2")} current={true}>
								Job Description
							</Tab>
							<Tab onClick={() => clickHandler("CL2")}>Cover Letter</Tab>
						</Tabs>
					</div>
					<div className="detailmodal-row">
						<div className="detailmodal-editor">
							{!editorContentsSource && (
								<div className="!z-40 bg-gray-500/5 absolute w-full h-full rounded">
									<LoadingWheel
										svgClasses={[
											"w-16",
											"h-16",
											"text-[#d0eac8]",
											"fill-[#61b946]"
										]}
										status={LoadingStatus.Server}
									/>
								</div>
							)}
							<MDXEditor
								className="detailmodal-mdxeditor border border-gray-200 rounded-lg"
								ref={editorRef}
								markdown={editorContentsSource ?? ""}
								readOnly={!editorEnabled || !editorContentsSource}
								contentEditableClassName={`
									prose
									max-w-full		
									jobdetails-resume-scroll
									${editorContentsSource ? "" : "italic !text-gray-400"}
									${editorEnabled ? "" : "jobdetails-resume-disabled"}
								`}
								onError={(error) => console.error(error)}
								plugins={[
									headingsPlugin(),
									listsPlugin(),
									linkPlugin(),
									linkDialogPlugin(),
									quotePlugin(),
									thematicBreakPlugin(),
									toolbarPlugin({
										toolbarContents: () =>
											editorEnabled ? (
												<>
													<UndoRedo />
													<BoldItalicUnderlineToggles />
													<BlockTypeSelect />
													<ListsToggle />
													<CreateLink />
													<InsertThematicBreak />
													{currentTabKey === "CL2" && (
														<RedoAi
															onClick={regenCoverLetter}
															disabled={
																!coverLetterQueryData ||
																coverLetterGenerationPending
															}
														/>
													)}
												</>
											) : (
												//TODO: Create a "Readonly ToolBar Component"
												<div className="h-7 italic">read-only</div>
											)
									})
								]}
							/>
						</div>
						<div className="detailmodal-history">
							<Timeline events={jsonData} />
						</div>
					</div>
				</div>
			)}
		</Modal>
	);
}

export default JobDetailsModal;
