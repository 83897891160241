import React, { useEffect, useRef, useState } from "react";

import "./Modal.css";

interface ModalProps {
	isOpen: boolean;
	hasCloseButton?: boolean;
	onClose?: () => void;
	styleClass?: string;
	children: React.ReactNode;
}

function Modal({
	isOpen,
	hasCloseButton,
	onClose,
	styleClass,
	children
}: ModalProps) {
	const [isModalOpen, setModalOpen] = useState(isOpen);

	// Reference to dialog element
	const modalRef = useRef<HTMLDialogElement | null>(null);

	// Sync isModalOpen state with isOpen prop
	useEffect(() => {
		setModalOpen(isOpen);
	}, [isOpen]);

	// Open/Close dialog element based on state
	useEffect(() => {
		const dialogElement = modalRef.current;

		if (dialogElement) {
			if (isModalOpen) {
				dialogElement.show();
			} else {
				dialogElement.close();
			}
		}
	}, [isModalOpen]);

	// Function to close the modal
	const handleCloseModal = () => {
		// Call the provided onClose handler, if any
		if (onClose) {
			onClose();
		}
		// Update state
		setModalOpen(false);
	};

	// Update state if dialog is closed with Escape key
	const handleKeyDown = (event: React.KeyboardEvent<HTMLDialogElement>) => {
		if (event.key === "Escape") {
			handleCloseModal();
		}
	};

	return (
		<>
			<div
				className={`fixed w-full h-full z-10 top-0 left-0 bg-black/10 ${isModalOpen ? "block" : "hidden"}`}
			/>
			<dialog
				className={`modal fixed z-20  ${styleClass ?? ""}`}
				ref={modalRef}
				onKeyDown={handleKeyDown}
			>
				{hasCloseButton && (
					<button
						className="modal-close-button material-symbols-outlined"
						onClick={(event) => {
							// Prevent click event from bubbling
							event.stopPropagation();
							handleCloseModal();
						}}
					>
						Close
					</button>
				)}
				{children}
			</dialog>
		</>
	);
}

export default Modal;
