import {
	APPLIED,
	CONFIRMED,
	RECRUITERINTERVIEW,
	HIRINGMANAGERINTERVIEW
} from "./Swimlanes";

import type { JobPostingData } from "./Swimlanes";
import type { ProfileData } from "./Profile";

// Check if local storage is available; from MDN web docs
const localStorageAvailable = () => {
	try {
		const testValue = "__storage_test__";
		window.localStorage.setItem(testValue, testValue);
		window.localStorage.removeItem(testValue);
		return true;
	} catch (e) {
		return (
			e instanceof DOMException &&
			(e.code === 22 || // Non-Firefox
				e.code === 1014 || // Firefox
				e.name === "QuotaExceededError" || // Non-Firefox
				e.name === "NS_ERROR_DOM_QUOTA_REACHED") && // Firefox
			window.localStorage &&
			window.localStorage.length !== 0
		);
	}
};

// fake data generator
const getJobs = (
	count: number,
	offset: number = 0,
	initialState: string = APPLIED,
	orderOffset: number = 0
) => {
	const fakeJobs = [];
	for (let i = offset; i < count + offset; i++) {
		fakeJobs.push({
			id: `${i}`,
			title: `Job ${i}`,
			description: `Description: Lorem Ipsum Local Storage`,
			company: `Company ${i}`,
			state: initialState,
			order: i + orderOffset
		});
	}
	return fakeJobs;
};

// Generate a predetermined list of fake job posting objects
const getFakeJobs = () => {
	return [
		...getJobs(2),
		...getJobs(1, 2, CONFIRMED),
		...getJobs(1, 3, RECRUITERINTERVIEW),
		...getJobs(1, 4, HIRINGMANAGERINTERVIEW)
	];
};

// Generate a fake profile
const getFakeProfile = (): ProfileData => {
	return {
		name: "John Doe",
		email: "john.doe@example.com",
		phone: "(123) 456-7890",
		title: "Software Engineer",
		address: "123 Main Street",
		city: "Anytown",
		state: "state",
		zipCode: "12345",
		linkedinUrl: new URL("https://www.linkedin.com/"),
		resumes: [],
		ksa: {
			knowledge: ["Java", "Python", "JavaScript", "React", "Node.js"],
			skills: ["Communication", "Teamwork"],
			abilities: ["Problem-solving", "Analytical"]
		}
	};
};

// Retrieve job postings from local storage and provide dummy data if not set
export function getUserJobPostings() {
	// Return undefined if local storage is inaccessible
	if (!localStorageAvailable()) {
		return undefined;
	}

	const storedValue = window.localStorage.getItem("userJobPostings");

	// If the value hasn't been set in local storage yet, it may be null, so set it here
	// With an actual database, this should never be the case
	if (!storedValue) {
		// Dummy jobs are inserted so the swimlanes aren't empty
		const jobsObject = { jobs: getFakeJobs() };
		window.localStorage.setItem("userJobPostings", JSON.stringify(jobsObject));
		return jobsObject;
	} else {
		return JSON.parse(storedValue);
	}
}

// Create a new job posting in the "applied" state and returns it if successful
export function createUserJobPosting(
	jobTitle: string,
	jobDescription: string,
	jobCompany: string
) {
	// Return undefined if local storage is inaccessible
	if (!localStorageAvailable()) {
		return undefined;
	}

	// Retrieve job postings from local storage to generate an unused job id
	// Disable null-checking here because the key is set during initialization
	const jobs: JobPostingData[] = JSON.parse(
		window.localStorage.getItem("userJobPostings")!
	).jobs;
	const ids = jobs.map((job) => {
		return parseInt(job.id);
	});

	// Find an unused id
	let unusedId = 0;
	for (let id of ids) {
		if (id === unusedId) {
			unusedId++;
		} else if (id > unusedId) {
			break;
		}
	}

	// Create posting object
	const jobData: JobPostingData = {
		id: `${unusedId}`,
		title: jobTitle,
		jobDescription: jobDescription,
		company: jobCompany,
		state: APPLIED,
		order: 0
	};

	// Shift other postings down
	jobs.forEach((job) => {
		if (job.state === jobData.state) {
			job.order++;
		}
	});

	// Insert job posting in local storage
	window.localStorage.setItem(
		"userJobPostings",
		JSON.stringify({
			jobs: [...jobs, jobData]
		})
	);

	// Return id of created job
	return jobData;
}

// Update the order and state of a job posting
export function updateUserJobPosting(
	jobId: string,
	jobOrder: number,
	jobState: string
) {
	// Return false if local storage is inaccessible
	if (!localStorageAvailable()) {
		return false;
	}

	// Disable null-checking here because the key is set during initialization
	const jobs: JobPostingData[] = JSON.parse(
		window.localStorage.getItem("userJobPostings")!
	).jobs;

	// Find the job; assume it exists
	const jobObject = jobs.find((job) => job.id === jobId)!;

	// Set up the source and destination lists
	let sourceList = jobs.filter((job) => job.state === jobObject.state);
	let destinationList =
		jobState !== jobObject.state
			? jobs.filter((job) => job.state === jobState)
			: sourceList;

	sourceList.sort((jobA, jobB) => jobA.order - jobB.order);
	destinationList.sort((jobA, jobB) => jobA.order - jobB.order);

	// Move the job posting
	sourceList.splice(jobObject.order, 1);
	destinationList.splice(jobOrder, 0, jobObject);

	// Update the job posting's state
	jobObject.state = jobState;

	// Update the order of job postings
	const setOrder = (job: JobPostingData, index: number) => {
		job.order = index;
	};
	sourceList.forEach(setOrder);
	if (destinationList !== sourceList) {
		destinationList.forEach(setOrder);
	}

	// Update local storage
	window.localStorage.setItem("userJobPostings", JSON.stringify({ jobs }));

	// Return true to indicate success
	return true;
}

// Retrieve a user's profile from local storage and provide dummy data if not set
export function getUserProfile() {
	// Return undefined if local storage is inaccessible
	if (!localStorageAvailable()) {
		return undefined;
	}

	const storedValue = window.localStorage.getItem("userProfile");

	// If the value hasn't been set in local storage yet, it may be null, so set it here
	// With an actual database, this should never be the case
	if (!storedValue) {
		// Dummy profile is inserted
		const profileObject = { profile: getFakeProfile() };
		window.localStorage.setItem("userProfile", JSON.stringify(profileObject));
		return profileObject;
	} else {
		return JSON.parse(storedValue);
	}
}
