import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "./ContactFormModal.css";
import Modal from "../Modal";

const ContactFormModal: React.FC = () => {
	const [isOpen, setIsOpen] = useState(false);

	const openModal = () => setIsOpen(true);
	const closeModal = () => setIsOpen(false);

	// Define the form reference type as HTMLFormElement or null
	const form = useRef<HTMLFormElement>(null);

	// Add proper typing for the event parameter
	const handleContactSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		emailjs
			.sendForm("service_r2b56bq", "template_t4ppi13", e.currentTarget, {
				publicKey: "14MIszdD7MNeQovxa"
			})
			.then(
				() => {
					console.log("SUCCESS!");
					console.log("Message Sent");
					closeModal();
				},
				(error) => {
					console.log("FAILED...", error.text);
				}
			);
	};

	const handleClose = () => {
		closeModal();
	};

	return (
		<div>
			<link
				href="https://fonts.googleapis.com/icon?family=Material+Icons"
				rel="stylesheet"
			/>
			<button onClick={openModal} className="material-button">
				<span className="material-icons">help_outline</span>
			</button>

			{isOpen && (
				<Modal isOpen={isOpen} onClose={handleClose} hasCloseButton={true}>
					<div className="modal-content">
						<p className="contact-form-video-text">
							<a
								href="https://storage.googleapis.com/website_jobmap_work/instructions.mp4"
								target="_blank"
								rel="noreferrer"
							>
								Click here
							</a>{" "}
							to watch the instructional video
						</p>
						<h1>
							<b>
								Still have questions?
								<br />
								Contact Us:
							</b>
						</h1>
						<br></br>
						<form ref={form} onSubmit={handleContactSubmit}>
							<label htmlFor="user_name">Name</label>
							<input type="text" id="user_name" name="user_name" required />

							<label htmlFor="user_email">Email</label>
							<input type="email" id="uesr_email" name="user_email" required />

							<label htmlFor="message">Message</label>
							<textarea id="message" name="message" required></textarea>

							<button type="submit" className="submit-btn" value="Send">
								Send
							</button>
						</form>
					</div>
				</Modal>
			)}
		</div>
	);
};

export default ContactFormModal;
