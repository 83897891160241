import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";

//GA4 Library: https://www.npmjs.com/package/react-ga4
//GA4 Chrome Extension Debugger: https://chromewebstore.google.com/detail/google-analytics-debugger/jnkmfdileelhofjcijamephohjechhna?hl=en&pli=1

interface AnalyticsProps {
	children: React.ReactElement;
	measurementId: string;
	analyticsCat: string;
	analyticsLbl: string;
}

const Analytics: React.FC<AnalyticsProps> = ({
	children,
	measurementId,
	analyticsCat,
	analyticsLbl
}) => {
	const [initialized, setInitialized] = useState(false);

	useEffect(() => {
		if (!initialized) {
			ReactGA.initialize(measurementId);
			setInitialized(true);
		}
	}, [measurementId, initialized]);

	const gaEventTracker = (action: string = "Unknown") => {
		ReactGA.event(action, {
			category: analyticsCat,
			label: analyticsLbl
		});
	};

	const enhancedProps = {
		onClick: (event: React.MouseEvent) => {
			gaEventTracker("click");
			if (children.props.onClick) {
				children.props.onClick(event);
			}
		},
		onMouseOver: (event: React.MouseEvent) => {
			gaEventTracker("hover");
			if (children.props.onMouseOver) {
				children.props.onMouseOver(event);
			}
		}
	};

	return React.cloneElement(children, enhancedProps);
};

export default Analytics;
