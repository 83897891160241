import React from "react";

interface TabProps {
	children: JSX.Element | string | JSX.Element[];
	current?: boolean;
	onClick: () => void;
}

const Tab = ({ children, current, onClick }: TabProps) => {
	const localClick = (event: any) => {
		//Step 1. Locate tabs (siblings of parent), clear CSS
		var tabs = event.target.parentElement.parentElement.children;

		for (let i = 0; i < tabs.length; i++) {
			tabs[i].classList.remove("tab-current");
		}

		//Step 2. Locate my tabs Add current CSS
		event.target.parentElement.classList.add("tab-current");

		//Step 3. Call back to the parent object with informaiton about the click.
		onClick();
	};

	var cssValue = current ? "tab-current tab" : "tab";

	return (
		<li className={cssValue}>
			<span onClick={localClick}>{children}</span>
		</li>
	);
};

export default Tab;
