import ReactDOM from "react-dom/client";
import {
	Outlet,
	Route,
	createBrowserRouter,
	createRoutesFromElements,
	RouterProvider,
	Navigate,
	useNavigate,
	useRouteError,
	isRouteErrorResponse
} from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import Landing from "./pages/Landing";
import SwimlanesPage from "./Swimlanes";
import { createIDBPersister } from "./services/idb-persister/idbPersister";
import { redirectDocument } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginPage from "./pages/LoginPage";
import AuthProvider from "./context/AuthProvider";
import PrivateRouteWrapper from "./components/PrivateRouteWrapper";
import FtuePage, { FtueErrorBoundary, ftueLoader } from "./pages/FtuePage";
import renderPrivacy from "./pages/Legal/PrivacyPolicy";
import renderTerms from "./pages/Legal/TermsService";
import React from "react";
import DebugPage from "./pages/DebugPage";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			// 24 hours. See: https://tanstack.com/query/latest/docs/framework/react/plugins/persistQueryClient/#how-it-works
			gcTime: 1000 * 60 * 60 * 24
		}
	}
});

const persister = createIDBPersister();

function Layout() {
	return (
		<div className="content">
			<ToastContainer containerId="toast-main" position="bottom-right" />
			<Outlet />
		</div>
	);
}

const RootErrorBoundary: React.FC = () => {
	const navigate = useNavigate();
	const error = useRouteError();
	if (isRouteErrorResponse(error)) {
		// Redirect to 404 Page
		if (error.status === 404) {
			window.location.replace("/exceptions/404.html");
			return <></>;
		}
	}
	// Generic error message
	return (
		<div className="text-center p-10">
			<img
				className="w-60 inline-block mb-4"
				src={process.env.PUBLIC_URL + "/MappyIcon.png"}
				alt="Mappy"
			/>
			<h1 className="text-3xl">Oops! Something went wrong!</h1>
			<button className="underline text-lg" onClick={() => navigate(0)}>
				Click here to reload
			</button>
		</div>
	);
};

function Main() {
	const browserRouter = createBrowserRouter(
		createRoutesFromElements(
			<Route path="/" element={<Layout />} errorElement={<RootErrorBoundary />}>
				<Route index element={<Navigate to="dnd" replace />} />
				<Route
					element={
						<PrivateRouteWrapper
							routePermission="pages/swimlanes"
							fallbackPath="/newLanding"
						/>
					}
				>
					<Route path="dnd" element={<SwimlanesPage />} />
				</Route>
				<Route path="terms" element={renderTerms()} />
				<Route path="privacy" element={renderPrivacy()} />
				<Route path="newLanding" element={<Landing />} />
				<Route
					path="landing"
					loader={() => {
						const landingURL =
							"https://storage.googleapis.com/website_jobmap_work/index.html";
						const search = new URL(window.location.toString()).search;
						return redirectDocument(landingURL + search);
					}}
				/>
				<Route path="login" element={<LoginPage />} />
				<Route
					element={
						<PrivateRouteWrapper
							routePermission="ftue/swimlanes"
							fallbackPath="/newLanding"
						/>
					}
					loader={ftueLoader}
					errorElement={<FtueErrorBoundary />}
				>
					<Route path="ftue" element={<FtuePage />} />
				</Route>
				<Route path="debug" element={<DebugPage />} />
			</Route>
		)
	);
	return (
		<PersistQueryClientProvider
			client={queryClient}
			persistOptions={{ persister }}
		>
			<AuthProvider>
				<RouterProvider router={browserRouter} />
			</AuthProvider>
		</PersistQueryClientProvider>
	);
}

ReactDOM.createRoot(document.getElementById("root")!).render(<Main />);
