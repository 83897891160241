import { Link } from "react-router-dom";

export default function DebugPage() {
	return (
		<div className="p-4">
			<h1 className="text-xl">~Super Secret Debug Page~</h1>
			<ul className="list-disc list-inside pl-2">
				<li>
					<Link className="underline" to="/dnd">
						Swimlanes Page
					</Link>
				</li>
				<li>
					<Link className="underline" to="/login">
						Login Page
					</Link>
				</li>
				<li>
					<a className="underline" href="/exceptions/404.html">
						404 Page
					</a>
				</li>
				<li>
					<Link className="underline" to="/newLanding">
						New Landing
					</Link>
				</li>
				<li>
					<Link className="underline" to="/landing">
						Old Landing
					</Link>
				</li>
				<li>
					<Link className="underline" to="/ftue">
						Swimlanes FTUE Page
					</Link>
				</li>
				<li>
					<Link className="underline" to="/terms">
						Terms and Conditions
					</Link>
				</li>
				<li>
					<Link className="underline" to="/privacy">
						Privacy Policy
					</Link>
				</li>
			</ul>
		</div>
	);
}
