import { get, set, del } from "idb-keyval";
import {
	PersistedClient,
	Persister
} from "@tanstack/react-query-persist-client";

export function createIDBPersister(
	idbValidKey: IDBValidKey = "REACT_QUERY_OFFLINE_CACHE"
) {
	return {
		persistClient: async (client: PersistedClient) => {
			try {
				await set(idbValidKey, client);
			} catch (err: any) {
				// Child objects of client may occasionally contain functions which cannot be serialized
				// This results in an error when calling set()
				if (err?.name === "DataCloneError") {
					// Convert to JSON & back to strip out any functions in the client that may be causing the error
					// This is safe to do because the default persisters use JSON.stringify anyways
					client = JSON.parse(JSON.stringify(client));
					await set(idbValidKey, client);
				} else {
					console.error(err);
				}
			}
		},
		restoreClient: async () => {
			return await get<PersistedClient>(idbValidKey).catch((error) =>
				console.error(error)
			);
		},
		removeClient: async () => {
			await del(idbValidKey).catch((error) => console.error(error));
		}
	} as Persister;
}
