import React, { ReactNode } from "react";
import "./legal.css";

export default function renderTerms(): ReactNode {
	return (
		<div className="legalBody">
			<h1>TERMS OF SERVICE</h1>
			<ol>
				<li>
					<div className="section-title">ACCEPTANCE OF TERMS</div>
					<div>
						These Terms of Service ("Terms") govern your access to and use of
						JobMap (the "Service"), a product offered by Keys and Castles
						("Company," "we," or "us"). By accessing or using the Service, you
						agree to be bound by these Terms. If you disagree with any part of
						the Terms, you may not access the Service.
					</div>
				</li>

				<li>
					<div className="section-title">DESCRIPTION OF SERVICE</div>
					<ol className="sub-list">
						<li>
							JobMap is a digital platform designed to assist job seekers in
							identifying employment opportunities through database utilization.
						</li>
						<li>
							The Company reserves the right to modify, suspend, or discontinue
							the Service at any time without notice.
						</li>
					</ol>
				</li>

				<li>
					<div className="section-title">ELIGIBILITY AND ACCOUNT CREATION</div>
					<ol className="sub-list">
						<li>
							To be eligible to use the Service, you must be at least 18 years
							of age and possess the legal capacity to enter into binding
							agreements.
						</li>
						<li>
							To create an account, you must provide accurate and complete
							information, including but not limited to your name, email
							address, credit card details, and resume.
						</li>
						<li>
							You are responsible for maintaining the confidentiality of your
							account information and for all activities that occur under your
							account.
						</li>
					</ol>
				</li>

				<li>
					<div className="section-title">USER OBLIGATIONS</div>
					<ol className="sub-list">
						<li>
							You agree to use the Service in compliance with all applicable
							laws and regulations.
						</li>
						<li>
							You shall not:
							<ul className="alpha-list">
								<li>Violate any intellectual property rights;</li>
								<li>Provide false or misleading information;</li>
								<li>
									Interfere with or disrupt the Service or servers or networks
									connected to the Service;
								</li>
								<li>
									Circumvent, disable, or otherwise interfere with
									security-related features of the Service.
								</li>
							</ul>
						</li>
					</ol>
				</li>

				<li>
					<div className="section-title">
						COMPANY RIGHTS AND RESPONSIBILITIES
					</div>
					<ol className="sub-list">
						<li>The Company shall provide the Service as described herein.</li>
						<li>
							The Company reserves the right to:
							<ul className="alpha-list">
								<li>
									Modify, suspend, or terminate the Service or any user's access
									to it;
								</li>
								<li>Remove content that violates these Terms;</li>
								<li>Update these Terms at any time.</li>
							</ul>
						</li>
					</ol>
				</li>

				<li>
					<div className="section-title">INTELLECTUAL PROPERTY</div>
					<ol className="sub-list">
						<li>
							All content and materials provided by the Company are protected by
							copyright and other intellectual property laws.
						</li>
						<li>
							Users retain rights to their own content but grant the Company a
							non-exclusive, worldwide, royalty-free license to use, reproduce,
							and distribute such content for the purposes of providing and
							improving the Service.
						</li>
					</ol>
				</li>

				<li>
					<div className="section-title">PRIVACY AND DATA PROTECTION</div>
					<ol className="sub-list">
						<li>
							The Company's collection and use of personal information is
							governed by its Privacy Policy, which is incorporated by reference
							into these Terms.
						</li>
					</ol>
				</li>

				<li>
					<div className="section-title">PAYMENT AND REFUNDS</div>
					<ol className="sub-list">
						<li>
							Payment terms and refund policies are as set forth in the
							Company's Refund Policy, available on our website.
						</li>
					</ol>
				</li>

				<li>
					<div className="section-title">TERMINATION</div>
					<ol className="sub-list">
						<li>
							Users may terminate their account at any time by following the
							procedures outlined in the Service.
						</li>
						<li>
							The Company may terminate or suspend access to the Service
							immediately, without prior notice or liability, for any reason
							whatsoever.
						</li>
					</ol>
				</li>

				<li>
					<div className="section-title">DISCLAIMER OF WARRANTIES</div>
					<ol className="sub-list">
						<li>
							THE SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS.
							THE COMPANY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS
							OR IMPLIED.
						</li>
					</ol>
				</li>

				<li>
					<div className="section-title">LIMITATION OF LIABILITY</div>
					<ol className="sub-list">
						<li>
							IN NO EVENT SHALL THE COMPANY BE LIABLE FOR ANY INDIRECT,
							INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING
							WITHOUT LIMITATION, LOSS OF PROFITS, DATA, USE, GOODWILL, OR OTHER
							INTANGIBLE LOSSES.
						</li>
					</ol>
				</li>

				<li>
					<div className="section-title">GOVERNING LAW AND JURISDICTION</div>
					<ol className="sub-list">
						<li>
							These Terms shall be governed by and construed in accordance with
							the laws of Deleware.
						</li>
						<li>
							Any dispute arising out of or in connection with these Terms shall
							be subject to the exclusive jurisdiction of the courts of
							Deleware.
						</li>
					</ol>
				</li>

				<li>
					<div className="section-title">AMENDMENTS</div>
					<ol className="sub-list">
						<li>
							The Company reserves the right to amend these Terms at any time.
							Users will be notified of significant changes, and continued use
							of the Service constitutes acceptance of the modified Terms.
						</li>
					</ol>
				</li>

				<li>
					<div className="section-title">SEVERABILITY</div>
					<ol className="sub-list">
						<li>
							If any provision of these Terms is held to be unenforceable or
							invalid, such provision will be changed and interpreted to
							accomplish the objectives of such provision to the greatest extent
							possible under applicable law, and the remaining provisions will
							continue in full force and effect.
						</li>
					</ol>
				</li>
			</ol>

			<p>
				BY USING THE SERVICE, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF
				SERVICE, UNDERSTAND THEM, AND AGREE TO BE BOUND BY THEM.
			</p>
		</div>
	);
}
