import { FirebaseOptions, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const appConfig: FirebaseOptions = {
	apiKey: "AIzaSyD3g685CD9OGygZhkQRgOkq7-nip4ah0H4",
	authDomain: "resumeanalyzer-178618.firebaseapp.com"
};

const app = initializeApp(appConfig);

export const auth = getAuth(app);
export default app;
